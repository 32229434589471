import HttpRequest from "./http_request";
import { api_url } from "../config";
const prefix = "/partner";

class PartnerService extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async ais_deal(body) {
    const { data } = await this.create(prefix+'/ais-deal', body);
    
    return data? data:false
  }
}

export default PartnerService;
