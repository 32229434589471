<template>
  <div class="deals-container">
    <div class="banner">
        <h1>สิทธิประโยชน์สำหรับลูกค้า</h1>
        <h1>AIS BIZ UP</h1>
        <img src="https://cdn.discordapp.com/attachments/973032956459376670/1062287904124190750/image_115.png"/>
        <div>
            <button @click="apply_type = 'provider'" style="padding: 0.25rem 0.5rem;border-radius: 6px;background-color: #b3d336;font-size: 22px;margin: 0.5rem;color: white;border: none;">ผู้ประกอบการสนามกีฬา</button>
            <button @click="apply_type = 'user'" style="padding: 0.25rem 0.5rem;border-radius: 6px;background-color: #b3d336;font-size: 22px;margin: 0.5rem;color: white;border: none;">ส่วนลดจองสนามกีฬา</button>
        </div>
    </div>
    <div class="deals-content">
        <h2><span style="font-size: 44px">
            {{apply_type == 'user'? 'สิทธิพิเศษสำหรับ SME':'รับสิทธิ์ฟรี' }}
        </span> {{ apply_type == 'provider'? '(ผู้ประกอบการสนามกีฬา)':'(ในโครงการ AIS BIZ UP)'}} </h2>
        <h2>{{ apply_type == 'provider'? 'ใช้งาน matchday ARENA แพ็คเกจพรีเมี่ยม 6 เดือน':'ส่งต่อส่วนลด 10% (สูงสุด 100 บาท) เมื่อจองสนามผ่านแอปพลิเคชั่น matchday ให้กับพนักงานของ SME ในโครงการ AIS BIZ UP' }}</h2>
        <h2 v-if="apply_type == 'provider'">มูลค่า 2,994 บาท จำนวน 10 สิทธิ์</h2>
        <div class="form-container">
            <div class="group-input">
                <label for="fullname" required>ชื่อ-นามสกุล</label>
                <input v-model="fullname" id="fullname" type="text" required />
            </div>
            <div class="row-input">
                <div class="group-input">
                    <label for="provider_name" required>{{ apply_type == 'provider'? 'ชื่อสนาม':'บริษัท' }}</label>
                    <input v-model="provider_name" id="provider_name" type="text" required/>
                </div>
                <div class="group-input">
                    <label for="sport" required>{{ apply_type == 'provider'? 'ประเภทกีฬา':'ตำแหน่ง' }}</label>
                    <input v-model="sport" id="sport" type="text" required/>
                </div>
            </div>
            <div class="row-input">
                <div class="group-input">
                    <label for="email" required>อีเมล</label>
                    <input v-model="email" id="email" type="text" required/>
                </div>
                <div class="group-input">
                    <label for="tel" required>เบอร์โทรติดต่อ</label>
                    <input v-model="phone_number" id="tel" type="text" required/>
                </div>
            </div>

            <div class="row-input">
                <div v-if="apply_type == 'provider'" class="group-input">
                    <label for="job" required>ตำแหน่ง</label>
                    <input v-model="job" id="job" type="text" required/>
                </div>
                <div class="group-input">
                    <label for="code" required>รหัสที่ได้รับจากการกด *545*800*{{ apply_type == 'provider'? '197':'200'}}# โทรออก</label>
                    <input v-model="code" id="code" type="text" required/>
                </div>
            </div>
            <div style="display: flex;align-items: center;padding:0 15px">
                <input v-model="checkbox" style="margin-right: 5px" type="checkbox" required/>
                <label style="font-size: 12px;font-weight: 700;margin: 0;">ยอมรับข้อกำหนดการให้บริการ และ นโยบายความเป็นส่วนตัว</label>
            </div>
            <div style="display: flex;align-items: center">
                <span style="font-size: 12px;font-weight: 700;margin: 20px">หมายเหตุ</span>
                <span style="font-size: 8px;font-weight: 700">เราจะรวบรวมข้อมูลของคุณเพื่อวัตถุประสงค์ทางการตลาด อย่างไรก็ตามเราเครารพสิทธิ์ความเป็นส่วนตัวของคุณ หากคุณต้องการเข้าถึงหรือแก้ไขข้อมูลส่วนบุคคลใดๆ <br>
                    ที่เรามีเกี่ยวกับคุณหรือขอให้เราลบข้อมูลใดๆ ที่เกี่ยวกับคุณที่เรารวบรวมไว้โปรดส่งอีเมลถึงเรา  admin@matchday.co.th</span>
            </div>
            <div style="display: flex;justify-content: center;width: 100%">
                <button @click="submit_ais()" style="width: 232px;height: 62px;border-radius: 6px;background-color: #b3d336;font-size: 26px;color: white;border: none;" :disabled="empty_fields">ส่งข้อมูล</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import PartnerService from '../resources/partner_service'

export default {
  name: 'AIS',
  data () {
    return {
        fullname: null,
        sport: null,
        email: null,
        phone_number: null,
        job: null,
        code: null,
        provider_name: null,
        checkbox: false,
        apply_type: 'provider'
    }
  },
  computed: {
    empty_fields() {
        const provider = !this.checkbox || !this.fullname || !this.sport || !this.email || !this.phone_number || !this.job || !this.code || !this.provider_name
        const user = !this.checkbox || !this.fullname || !this.sport || !this.email || !this.phone_number || !this.code || !this.provider_name
        return this.apply_type == 'provider'? provider:user
    }
  },
  methods: {
    clear_input() {
        this.fullname = null
        this.sport = null
        this.email = null
        this.phone_number = null
        this.job = null
        this.code = null
        this.provider_name = null
        this.checkbox = false
    },
    async submit_ais () {
        const body = {
            fullname: this.fullname,
            sport: this.sport,
            email: this.email,
            phone_number: this.phone_number,
            job: this.job,
            code: this.code,
            provider_name: this.provider_name
        }


        const partner_service = new PartnerService()
        const { status } = await partner_service.ais_deal(body)
        switch (status) {
            case 200:
                Swal.fire({
                    title: 'ส่งข้อมูลสำเร็จ',
                    text: 'ขอบคุณที่สนใจใช้บริการกับเรา',
                    icon: 'success',
                    confirmButtonText: 'ตกลง'
                })
                this.clear_input()
                break;
            case 500:
                Swal.fire({
                    title: 'ส่งข้อมูลไม่สำเร็จ',
                    text: 'โปรดตรวจสอบรหัสโปรโมชั่นอีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                break;
            default:
                break;
        }
    }
  },
  mounted () {
    // this.$emit('request_fullscreen', true)
  }
}
</script>

<style scoped>
@media (max-width: 1200px) {
    .banner {
        text-align: center;
    }
    .deals-container {
        flex-direction: column;
    }
    .row-input {
        flex-direction: column;  
    }

    img {
        width: 80vw;
    }
}
.row-input {
    display: flex;
    justify-content: space-between;
}
.group-input {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
}
button:disabled {
    background-color: #d9d9d9 !important;
    cursor: not-allowed;
}
label {
    font-size: 16px;
    font-weight: 700;
}
input {
    background-color: #d9d9d9;
    border-radius: 8px;
    padding: 12px;
    border: none;
}
h1 {
    font-weight: 700;
    font-size: 38px;
    color: black;
    padding: 15px;
}
h2 {
    font-weight: 700;
    font-size: 34px;
    padding: 0 15px;
}
.deals-container {
    display: flex;
    min-height: 90vh;
}
.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.deals-content {
    width: 100%;
    padding: 40px;
    background: linear-gradient(180deg, #ef3925 0%, #f31910 27.15%, #000000 100%);
    display: flex;
    flex-direction: column;
    color: white;
}
</style>